<template>
  <div id="external-request" :style="{ width: formWidth }">
    <BaseLoadingBarSheet v-if="loadingBarContent" class="loading-content" />

    <!-- header -->
    <div class="header col">
      <div class="col text-center">
        <div class="title-1">{{ title }}</div>
        <div class="description">{{ description }}</div>
      </div>

      <template
        v-if="
          workflow.initiatedBy === 'FORM' && Object.keys(selectedProcess).length
        "
      >
        <template v-if="mandatory.filledValue || mandatory.nonFilledValue">
          <q-circular-progress
            show-value
            font-size="12px"
            :value="mandatoryFilling"
            size="lg"
            :thickness="0.22"
            color="primary"
            track-color="grey-3"
            class="q-mr-sm"
          >
            {{ mandatoryFilling }}%
          </q-circular-progress>
        </template>
        <div v-if="mandatory.filledValue" class="chip-success q-mr-sm">
          <div class="">{{ mandatory.filledValue }} ready</div>
        </div>
        <div
          v-if="mandatory.nonFilledValue"
          class="chip-warning"
          @click="checkFormValidation"
        >
          <div class="">{{ mandatory.nonFilledValue }} need to work</div>
        </div>
        <template v-if="mandatoryDocument.length">
          <div
            class="chip-warning cursor-pointer"
            @click="attachmentSheet = true"
          >
            <div class="">{{ mandatoryDocument.length }} document required</div>
          </div>
        </template>

        <BaseActionButton
          v-if="!formView"
          v-tooltip.top="'Change View'"
          is-flat
          :icon="'mdi-form-select'"
          class="q-mr-xs"
          @click="formView = true"
        />

        <BaseActionButton
          v-if="formView"
          v-tooltip.top="'Change View'"
          is-flat
          :icon="'mdi-table-column'"
          class="q-mr-xs"
          @click="formView = false"
        />

        <ThemeSwitcher class="q-ml-sm" :no-border="false" />
      </template>
    </div>
    <!-- ... -->

    <!-- content -->

    <template v-if="!loadingBarContent && Object.keys(selectedProcess).length">
      <div id="content-wrapper" class="content">
        <div class="row">
          <template v-if="formView || workflow.initiatedBy === 'DOCUMENT_SIGN'">
            <div v-if="formId" id="form-view" class="col">
              <BaseScrollbar
                :height="
                  processActions.length
                    ? 'calc(100vh - 210px)'
                    : 'calc(100vh - 155px)'
                "
              >
                <RenderForm
                  :form-id="formId"
                  :panels="panels"
                  :secondary-panels="secondaryPanels"
                  :form-settings="formSettings"
                  :form-model="formModel"
                  :workflow-actions="[]"
                  :save-action.sync="saveAction"
                  :workflow-form-model.sync="workflowFormModel"
                  :workflow-model.sync="workflowModel"
                  :is-readonly="formEditAccess"
                  :form-edit-controls="formEditControls"
                  :readonly-controls="readonlyControls"
                  :form-visibility-access="formVisibilityAccess"
                  :form-secure-controls="formSecureControls"
                  :enable-controls="_enableControls"
                  :form-upload-files="formUploadFiles"
                  :has-check-form-validate.sync="hasCheckFormValidate"
                  :ocr-result="ocrResult"
                  @uploadFiles="getOCRResult"
                  @mandatoryControlsList="mandatoryControlsList"
                  @deletedFiles="deletedFiles"
                />
              </BaseScrollbar>
            </div>
            <div
              v-else-if="selectedProcess.itemData.itemId"
              id="document-view"
              class="col-12 q-pa-xs"
            >
              <iframe
                id="file-frame"
                ref="myProcessFiles"
                :src="itemLink()"
                frameborder="0"
                style="width: 100%; height: 78vh"
              ></iframe>
            </div>
          </template>
          <template v-else>
            <div :key="selectedProcess.processId + 'det'" class="col">
              <BaseScrollbar
                :height="
                  processActions.length
                    ? 'calc(100vh - 210px)'
                    : 'calc(100vh - 155px)'
                "
              >
                <div class="col">
                  <div id="requestDetails" class="col-12">
                    <div class="row justify-center">
                      <div class="col q-pa-md">
                        <div class="row items-center">
                          <table class="submittedData q-mb-sm">
                            <tbody>
                              <tr v-if="selectedProcess.createdByName">
                                <td colspan="4">
                                  Requested By:
                                  {{ selectedProcess.createdByName }}
                                </td>
                              </tr>
                              <tr>
                                <th v-if="selectedProcess.raisedBy">
                                  Requested User Email
                                </th>
                                <th>Requested Date</th>
                                <th>Last Acted By</th>
                                <th>Last Acted On</th>
                              </tr>
                              <tr>
                                <td v-if="selectedProcess.raisedBy">
                                  {{ selectedProcess.raisedBy }}
                                </td>
                                <td>
                                  {{ $day.format(selectedProcess.raisedAt) }}
                                </td>
                                <td>
                                  {{
                                    selectedProcess.transaction_createdByEmail
                                  }}
                                </td>
                                <td>
                                  {{
                                    $day.format(
                                      selectedProcess.transaction_createdAt
                                    )
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <BaseScrollbar
                            :height="
                              processActions.length
                                ? 'calc(100vh - 370px)'
                                : 'calc(100vh - 315px)'
                            "
                          >
                            <template v-if="requestFormData.panels">
                              <table class="mainData col-12">
                                <template
                                  v-for="panel in requestFormData.panels"
                                >
                                  <tr
                                    :key="panel.name"
                                    style="cursor: pointer"
                                    @click="
                                      panel.panelsExpandView =
                                        !panel.panelsExpandView
                                    "
                                  >
                                    <td style="color: #643094" colspan="2">
                                      <BaseIcon
                                        v-if="!panel.panelsExpandView"
                                        name="eva-arrow-forward-outline"
                                        size="20px"
                                        color="primary"
                                        class="icon"
                                      />

                                      <BaseIcon
                                        v-else
                                        name="eva-arrow-downward-outline"
                                        size="20px"
                                        color="primary"
                                        class="icon"
                                      />
                                      {{ panel.name || "No Title" }}
                                    </td>
                                  </tr>
                                  <template v-if="panel.panelsExpandView">
                                    <template
                                      v-for="(value, key) in panel.fields"
                                    >
                                      <template v-if="value && value != '[]'">
                                        <template
                                          v-if="validateType(key) !== 'TABLE'"
                                        >
                                          <tr :key="key">
                                            <td
                                              class="tblHeader ellipsis small"
                                            >
                                              {{ getLabel(key) }}
                                            </td>
                                            <td class="ellipsis">
                                              <template
                                                v-if="
                                                  validateType(key) ===
                                                  'FILE_UPLOAD'
                                                "
                                              >
                                                <div
                                                  v-if="
                                                    JSON.parse(value).length
                                                  "
                                                >
                                                  <div
                                                    v-for="file in JSON.parse(
                                                      value
                                                    )"
                                                    :key="file.fileName"
                                                    class="q-mb-xs"
                                                  >
                                                    <div
                                                      class="row items-center"
                                                    >
                                                      <div
                                                        class="col row items-center"
                                                        @click="
                                                          viewFile(file, value)
                                                        "
                                                      >
                                                        <FileIcon
                                                          :mime-type="
                                                            fileType(
                                                              file.fileName
                                                            )
                                                          "
                                                          class="mini-avatar q-mr-sm"
                                                        />
                                                        <span
                                                          class="cursor-pointer text-underline text-secondary"
                                                          >{{
                                                            file.fileName
                                                          }}</span
                                                        >
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </template>
                                              <template
                                                v-else-if="
                                                  validateType(key) === 'DATE'
                                                "
                                              >
                                                {{ $day.format(value, false) }}
                                              </template>
                                              <template
                                                v-else-if="validateValue(value)"
                                              >
                                                {{ removeTags(value) || "-" }}
                                              </template>
                                              <template v-else>
                                                <a
                                                  :href="value"
                                                  target="_blank"
                                                >
                                                  {{ value || "-" }}
                                                </a>
                                              </template>
                                            </td>
                                          </tr>
                                        </template>
                                        <template
                                          v-else-if="JSON.parse(value).length"
                                        >
                                          <tr
                                            v-if="getLabel(key)"
                                            :key="'trlabel' + key"
                                          >
                                            <td
                                              colspan="2"
                                              class="ellipsis small text-primary"
                                            >
                                              {{ getLabel(key) }}
                                            </td>
                                          </tr>
                                          <tr :key="'trtable' + key">
                                            <td colspan="2">
                                              <BaseScrollbar
                                                :key="'scroll' + key"
                                                style="max-height: 200px"
                                              >
                                                <table
                                                  :key="'table' + key"
                                                  class="sub-table q-mt-sm q-mb-sm"
                                                >
                                                  <thead>
                                                    <tr>
                                                      <th
                                                        v-for="(
                                                          field_item, field_key
                                                        ) in tableValue(
                                                          value
                                                        )[0]"
                                                        :key="field_key"
                                                      >
                                                        {{
                                                          getLabel(
                                                            field_key,
                                                            "table"
                                                          )
                                                        }}
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr
                                                      v-for="(
                                                        field_item, field_key
                                                      ) in tableValue(value)"
                                                      :key="field_key"
                                                    >
                                                      <td
                                                        v-for="(
                                                          row_value, row_key
                                                        ) in field_item"
                                                        :key="row_key"
                                                      >
                                                        {{ row_value }}
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </BaseScrollbar>
                                            </td>
                                          </tr>
                                        </template>
                                      </template>
                                    </template>
                                  </template>
                                </template>
                              </table>
                            </template>
                          </BaseScrollbar>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </BaseScrollbar>
            </div>
          </template>

          <div
            v-if="workflow.initiatedBy !== 'DOCUMENT_SIGN'"
            class="col-auto q-mr-sm leftBorder"
          >
            <template v-for="tab in tabs">
              <BaseActionButton
                :key="tab.id"
                v-tooltip.left="tab.label"
                is-flat
                no-border
                :icon="tab.icon"
                color="primary"
                class="tabButtons"
                @click="showLinks(tab.value)"
              >
                <span v-if="tab.count" class="badge">{{ tab.count }} </span>
              </BaseActionButton>
            </template>
          </div>
        </div>
        <!-- footer -->
        <div
          v-if="
            workflow.initiatedBy !== 'DOCUMENT_SIGN' &&
            processActions.length &&
            selectedProcess.review === ''
          "
          class="action-footer border-top q-pa-sm"
        >
          <BaseButton
            label="Save"
            class="q-ml-md"
            color="secondary"
            @click="saveAction = 'Save'"
          />
          <template v-for="action in processActions">
            <BaseButton
              v-if="
                action === 'Approve' ||
                action === 'Complete' ||
                action === 'Rightsize' ||
                action.indexOf('Approve') > -1
              "
              :key="action"
              :label="action"
              color="positive"
              class="q-ml-md"
              @click="saveAction = action"
            />
            <BaseButton
              v-else-if="
                action === 'Reject' ||
                action === 'Terminate' ||
                action === 'Force Close' ||
                action === 'Decline' ||
                action.indexOf('Reject') > -1
              "
              :key="action"
              :label="action"
              color="red"
              class="q-ml-md"
              @click="saveAction = action"
            />
            <BaseButton
              v-else-if="
                action === 'Forward' ||
                action.indexOf('Reply') > -1 ||
                action === 'Rollback' ||
                action === 'Assign'
              "
              :key="action"
              :label="action"
              color="orange"
              class="q-ml-md"
              @click="saveAction = action"
            />
            <BaseButton
              v-else-if="action === 'To Requester'"
              :key="action"
              :label="action"
              color="pink"
              class="q-ml-md"
              @click="saveAction = action"
            />
            <BaseButton
              v-else
              :key="action"
              :label="action"
              class="q-ml-md"
              @click="saveAction = action"
            />
          </template>
        </div>
        <!-- ... -->
      </div>
    </template>
    <template v-else-if="!loadingBarContent">
      <StateWrapper
        icon="eva-archive-outline"
        title="Process Request"
        description="Request details not found"
      />
    </template>

    <!-- ... -->

    <!-- footer -->

    <div class="footer">
      <div class="label">Powered by</div>
      <img src="@/assets/logo/logo-light.png" alt="logo" />
    </div>

    <!-- ... -->

    <!-- attachments -->

    <Sheet
      v-model="attachmentSheet"
      width="30vw"
      :sub-actions="checkListTemplate"
      :sub-actions-height="105"
      :upload="processActions.length ? true : false"
      @input="attachmentSheet = false"
    >
      <!-- title -->

      <template #title> Attachments </template>

      <template #actions>
        <div
          v-if="selectedProcess.processId"
          class="q-ml-sm text-gray q-mr-sm ellipsis"
          style="font-size: 10px"
        >
          auto-refresh every 15 seconds
        </div>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          class="q-mr-xs"
          no-border
          is-flat
          size="20px"
          @click="getAttachments"
        />
      </template>

      <template v-if="processActions.length" #upload>
        <div
          class="hint"
          @click="
            {
              rowIndex = null;
              $refs.attachments.click();
            }
          "
        >
          <BaseIcon
            name="eva-cloud-upload-outline"
            size="32px"
            color="blue"
            class="icon"
          />

          <div class="title text-blue">UPLOAD FILES</div>
          <div class="description">Click here to choose files and upload</div>
        </div>
      </template>

      <template #subActions>
        <div class="q-mt-sm">
          <div class="row q-mb-sm">
            <div class="q-mt-xs">Checklist Documents</div>
            <q-space />
            <div @click="downloadAll">
              <q-chip outline color="grey" class="chips grey-hover large">
                Download All
              </q-chip>
            </div>
          </div>
          <div class="row q-mb-md description text-sm text-grey">
            It can be useful to ensure that all necessary information and
            supporting documents are included
          </div>
        </div>
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar
          :height="
            processActions.length
              ? 'calc(100vh - 210px)'
              : 'calc(100vh - 150px)'
          "
        >
          <input
            ref="attachments"
            type="file"
            class="hidden"
            @change="uploadWflowAttachments"
          />
          <template v-for="row in checkList">
            <template v-if="row.name">
              <div v-if="!row.attach" :key="row.uid" class="checkList">
                <div class="row">
                  <div class="col-auto">
                    <BaseIcon name="mdi-file-check-outline" color="primary" />
                  </div>
                  <div class="col q-ml-sm">
                    <div class="row">
                      <div class="col-12">
                        {{ row.name }}
                      </div>
                      <div v-if="row.required" class="col-12 q-mt-sm">
                        <q-chip outline color="red" class="chips small">
                          Required
                        </q-chip>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="col-12" @click="attachTemplate(row.uid)">
                      <q-chip
                        outline
                        color="primary"
                        class="chips primary-hover medium"
                      >
                        <q-avatar
                          icon="eva-upload-outline"
                          class="q-mr-sm"
                          color="primary"
                          text-color="white"
                        />
                        Upload
                      </q-chip>
                    </div>
                    <div
                      v-if="row.template"
                      class="col-12"
                      @click="download(row.id)"
                    >
                      <q-chip
                        outline
                        color="secondary"
                        class="chips secondary-hover medium"
                      >
                        <q-avatar
                          icon="eva-download-outline"
                          class="q-mr-sm"
                          color="secondary"
                          text-color="white"
                        />
                        Download
                      </q-chip>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>
          <template v-for="file in attachmentList">
            <div :key="file.id" class="attachmentList">
              <div class="title row cursor-pointer">
                <div v-if="selectedProcess.processId" class="col-auto">
                  <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->
                  <BaseIcon
                    :name="
                      file.checked
                        ? 'eva-checkmark-circle-2'
                        : 'eva-radio-button-off-outline'
                    "
                    color="secondary"
                    class="q-mr-sm"
                    @click="file.checked = !file.checked"
                  />
                </div>
                <!-- <div v-if="file.formJsonId" class="col-auto">
                  <BaseIcon
                    v-tooltip:green.top="getLabel(file.formJsonId)"
                    name="mdi-form-select"
                    color="primary"
                    class="q-mr-sm"
                  />
                </div> -->
                <div class="col-auto">
                  <FileIcon
                    :mime-type="fileType(file.name)"
                    class="mini-avatar q-mr-sm"
                  />
                </div>
                <div class="col ellipsis" @click="openFile(file)">
                  {{ file.name }}
                </div>

                <div class="col-auto">
                  <BaseActionButton
                    v-tooltip.top="'Download'"
                    icon="eva-download-outline"
                    color="primary"
                    no-border
                    is-flat
                    size="20px"
                    @click.stop="fileMenuAction(file, 'Download')"
                  />
                </div>
                <div class="col-auto">
                  <BaseActionButton
                    v-tooltip.top="'delete'"
                    icon="eva-trash-2-outline"
                    color="red"
                    no-border
                    is-flat
                    size="20px"
                    @click.stop="attachmentDelete(file)"
                  />
                </div>
              </div>

              <div v-if="file.formJsonId" class="row q-mb-sm">
                <div class="col-auto ellipsis attachment-label">
                  {{ getLabel(file.formJsonId) }}
                </div>
              </div>

              <div class="meta row">
                <!-- created at -->

                <div>{{ $day.format(file.createdAt) }}</div>

                <!-- ... -->

                <!-- created by -->

                <template v-if="file.createdBy">
                  <BaseIcon name="mdi-circle-small" />
                  <div>{{ file.createdByEmail }}</div>
                </template>

                <!-- ... -->

                <!-- size -->

                <!-- <template v-if="file.size">
                <BaseIcon name="mdi-circle-small" />
                <div>{{ fileSize(file.size) }}</div>
              </template> -->

                <!-- ... -->
              </div>
            </div>
          </template>
        </BaseScrollbar>

        <div class="q-mt-xs defaultBottom">
          <BaseIcon name="mdi-circle-medium" color="red" /> Required Documents
        </div>
      </div>
    </Sheet>

    <!-- ... -->

    <!-- comments -->
    <Sheet
      v-model="commentsSheet"
      width="30vw"
      height="80vh"
      custom-height="30"
      @input="closeCommnetsSheet = false"
    >
      <!-- title -->

      <template #title> Comments </template>

      <!-- ... -->

      <template #actions>
        <div
          v-if="selectedProcess.processId"
          class="q-ml-sm text-gray q-mr-sm ellipsis"
          style="font-size: 10px"
        >
          auto-refresh every 30 seconds
        </div>

        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getComments()"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar
          :height="
            processActions.length ? 'calc(100vh - 155px)' : 'calc(100vh - 60px)'
          "
        >
          <!-- comments -->
          <template v-for="(row, idx) in commentsList">
            <q-chat-message
              :key="idx"
              :name="
                row.createdBy === $store.state.session.id
                  ? 'me'
                  : row.createdByEmail
              "
              :text="[row.comments]"
              text-html
              :sent="row.createdBy === $store.state.session.id"
              :stamp="lastCommentDuration(row)"
              :bg-color="
                row.createdBy === $store.state.session.id
                  ? 'primary-1'
                  : 'secondary-2'
              "
              class="q-ma-sm"
            />
          </template>
          <!-- ... -->
        </BaseScrollbar>
      </div>

      <!-- ... -->

      <div v-if="processActions.length" class="col q-pa-sm borderTop">
        <div class="row">
          <div class="col">
            <TextAreaField
              v-model="commentText"
              auto-focus
              placeholder="Type a new comment..."
            />
          </div>
          <div class="col-auto" style="margin: auto">
            <BaseActionButton
              v-tooltip.left="'Post Comments'"
              icon="eva-paper-plane-outline"
              color="primary-11"
              class="q-ml-sm commentAction"
              size="16px"
              rotate-class="rotate-45"
              @click="saveComment"
            />
            <BaseActionButton
              v-tooltip.left="'Advanced Comments'"
              icon="mdi-comment-text-multiple"
              color="primary-11"
              class="q-ml-sm q-mt-sm commentAction"
              size="16px"
              @click="advancedCommentsSheet = true"
            />
          </div>
        </div>
      </div>
    </Sheet>

    <!-- ... -->

    <!-- file -->

    <Sheet
      v-model="fileSheet"
      no-padding
      width="100vw"
      @input="fileSheet = false"
    >
      <!-- title -->

      <template #title>
        <div class="attachmentFileHeader">
          <template v-if="selectedFile.formJsonId">
            <BaseActionButton
              v-tooltip:green.bottom="getLabel(selectedFile.formJsonId)"
              is-flat
              icon="mdi-form-select"
              color="primary"
              class="q-mr-sm"
            />
          </template>
          <div class="avatar q-ml-sm">
            <FileIcon :mime-type="fileType(selectedFile.name)" />
          </div>

          <div class="q-ml-md">
            <div class="filename">
              {{ selectedFile.name }}
            </div>

            <div class="meta">
              <template v-if="selectedFile.createdAt">
                <div>
                  Archived At: {{ $day.format(selectedFile.createdAt) }}
                </div>
                <BaseIcon name="mdi-circle-small" />
              </template>
              <template v-if="selectedFile.createdByEmail">
                <div>Archived By: {{ selectedFile.createdByEmail }}</div>
              </template>
              <div v-if="selectedFile.size">
                <BaseIcon name="mdi-circle-small" />
                File Size: {{ fileSize(selectedFile.size) }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'Actions'"
          is-flat
          icon="eva-menu"
          class="q-mr-sm"
        >
          <q-menu
            transition-show="scale"
            transition-hide="scale"
            anchor="bottom end"
            self="top right"
          >
            <BaseScrollbar width="240px" class="q-pb-sm">
              <!-- columns -->

              <ListItem
                v-for="action in documentActionList"
                :key="action.id"
                :icon="action.icon"
                :label="action.label"
                @click="documentAction(action.id)"
              >
              </ListItem>
            </BaseScrollbar>

            <!-- ... -->
          </q-menu>
        </BaseActionButton>
      </template>

      <div class="attachmentFileFrame">
        <div class="file-list">
          <iframe
            id="file-frame"
            ref="myFiles"
            :src="fileLink()"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </Sheet>

    <!-- ... -->

    <Confirm
      v-model="successModal"
      icon="eva-checkmark-circle-2"
      icon-color="green"
      size="24px"
      @input="closeSuccessModal"
    >
      <template #title>Action on Request!</template>

      <template #default>
        <div class="q-py-md text-lg">
          <p>Thank you for taking action on the request</p>
        </div>
      </template>

      <template #footer>
        <BaseButton label="Close" color="primary" @click="closeSuccessModal" />
      </template>
    </Confirm>

    <Confirm
      v-model="alertModal"
      icon="mdi-file-alert"
      icon-color="red"
      @input="closeAlertModal"
    >
      <template #title>Documents Required!</template>

      <template #default>
        <div class="q-py-md text-md">
          <p>
            Are you sure you want to submit the request without attaching the
            required documents.
          </p>

          <p class="text-secondary">
            After registration, you will get the link to track your request.<br />From
            there, you can upload the required documents once you have them.
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          @click="closeAlertModal"
        />
        <BaseButton label="yes" color="primary" @click="processRequest" />
      </template>
    </Confirm>

    <Modal
      v-model="advancedCommentsSheet"
      width="60vw"
      height="80vh"
      has-footer
      @input="advancedCommentsSheet = false"
    >
      <!-- title -->

      <template #title> Comments</template>

      <!-- ... -->

      <template #default>
        <TextBuilderField v-model="advancedCommentText" />
      </template>

      <template #footer>
        <BaseButton
          label="Post"
          icon="eva-paper-plane-outline"
          color="primary"
          @click="saveAdvancedComment"
        />
      </template>
    </Modal>

    <MetaDataEdit
      :value="showMetaDataEditModal"
      :file="workflow"
      :repository-field="repositoryField"
      :file-data="fileData"
      title="Index"
      :columns="_columns"
      :modal-value="workflowModel"
      :upload-filename="uploadFilename"
      @close="closeMetaDataEditModal"
      @save="saveMetaData"
    />

    <ProcessHistory
      v-if="historyProcessId"
      :process-id="historyProcessId"
      :selected-process="selectedProcess"
      @close="historyProcessId = 0"
    />

    <Modal
      v-model="processForwardModal"
      width="40vw"
      height="50vh"
      has-footer
      @input="
        {
          saveAction = '';
          processForwardModal = false;
        }
      "
    >
      <!-- title -->

      <template #title
        >"{{ selectedProcess.requestNo }}"
        <template> Process Forward </template>
      </template>

      <!-- ... -->

      <template #default>
        <div id="processForward">
          <!-- user -->

          <MultiSelectField
            v-if="internalForward.proceed"
            v-model="forwardUsers"
            label="users"
            is-mandatory
            :options="
              internalForward.users.length ? internalForward.users : userList
            "
            class="field"
          />

          <!-- ... -->

          <!-- group -->

          <MultiSelectField
            v-if="internalForward.groups.length"
            v-model="forwardGroups"
            label="group"
            :options="
              internalForward.groups.length ? internalForward.groups : groupList
            "
            class="field"
          />

          <!-- ... -->
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Forward"
          color="orange"
          @click="
            {
              processForwardModal = false;
              requestAction('Forward', false, false);
            }
          "
        />
      </template>
    </Modal>

    <Confirm
      v-model="actionPasswordConfirm"
      icon="eva-alert-circle-outline"
      icon-color="red"
      @input="
        {
          saveAction = '';
          workflowFormModel = '';
          actionPasswordConfirm = false;
        }
      "
    >
      <template #title> Action Password</template>

      <template #default>
        <ValidationObserver ref="passwordForm">
          <!-- password -->

          <ValidationProvider
            v-slot="{ errors }"
            name="password"
            :rules="{ required: true, password: true }"
          >
            <PasswordField
              v-model="actionUserPassword"
              is-mandatory
              :is-readonly="isLoading"
              :error="errors[0]"
              :auto-focus="true"
              class="q-my-lg"
              @enter="validatePassword"
            />
          </ValidationProvider>

          <!-- ... -->
        </ValidationObserver>
      </template>

      <template #footer>
        <BaseButton
          label="validate"
          :is-loading="isLoading"
          @click="validatePassword"
        />
      </template>
    </Confirm>

    <Confirm
      v-model="actionConfirmAlert"
      icon="eva-alert-circle-outline"
      icon-color="red"
      @input="actionConfirmAlert = false"
    >
      <template #title> Confirmation</template>

      <template #default>
        <div class="q-py-md">
          <p>
            Are you sure you want to confirm the action as "
            <b>{{ saveAction }}</b
            >"?
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          color="primary"
          @click="
            {
              saveAction = '';
              workflowFormModel = {};
              actionConfirmAlert = false;
            }
          "
        />
        <BaseButton
          label="yes"
          color="primary"
          @click="
            {
              actionConfirmAlert = false;
              processRequest(false, false);
            }
          "
        />
      </template>
    </Confirm>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  auth,
  report,
  workflow,
  form,
  repository,
  uploadAndIndex,
  user,
  group,
} from "@/api/factory.js";
import { mapState } from "vuex";
import StateWrapper from "@/components/common/state/StateWrapper.vue";
import ThemeSwitcher from "@/layouts/app/components/toolbar/components/ThemeSwitcher.vue";
import RenderForm from "@/components/common/render-form/RenderForm.vue";
import Sheet from "@/components/common/popup/Sheet.vue";
import formatBytes from "@/helpers/format-bytes.js";
import {
  pdfSupport,
  htmlSupport,
  otherFilePDFSupport,
  fileSupport,
} from "@/helpers/file-format.js";
import axios from "axios";
import FileIcon from "@/components/common/FileIcon.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import Modal from "@/components/common/popup/Modal.vue";
import Confirm from "@/components/common/popup/Confirm.vue";
import TextBuilderField from "@/components/common/form/text-builder-field/TextBuilderField.vue";
import ListItem from "@/components/common/ListItem.vue";
import MetaDataEdit from "@/views/repositories/views/repository-browse-and-trash/components/components/MetaDataEdit";
import { fileToBase64 } from "@/helpers/file-to-base64.js";
import { decrypt } from "@/helpers/crypto";
import { lowerCase } from "lodash-es";
import ProcessHistory from "@/components/common/workflow/ProcessHistory.vue";
import PasswordField from "@/components/common/form/password-field/PasswordField.vue";

export default {
  name: "ExternalRequest",

  components: {
    ValidationObserver,
    ValidationProvider,
    StateWrapper,
    ThemeSwitcher,
    RenderForm,
    Sheet,
    FileIcon,
    TextAreaField,
    Confirm,
    Modal,
    TextBuilderField,
    ListItem,
    MetaDataEdit,
    ProcessHistory,
    PasswordField,
  },

  props: {
    tenantId: {
      type: String,
      required: true,
    },

    userId: {
      type: String,
      required: true,
    },

    workflowId: {
      type: String,
      required: true,
    },

    processId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loadingBarContent: true,
      formWidth: "100%",
      title: "",
      description: "",
      workflow: {},
      formId: 0,
      form: null,
      formModel: {},
      tabs: [
        {
          id: this.$nano.id(),
          label: "attachments",
          name: "attachmentsCount",
          value: "ATTACHMENTS",
          icon: "mdi-attachment",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "comments",
          name: "commentsCount",
          value: "COMMENTS",
          icon: "mdi-comment-text-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "history",
          value: "HISTORY",
          icon: "mdi-history",
          count: 0,
        },
      ],
      attachmentLoad: null,
      attachmentSheet: false,
      commentsLoad: null,
      commentsSheet: false,
      commentText: "",
      commentsList: [],
      advancedCommentsSheet: false,
      advancedCommentText: "",
      attachmentList: [],
      fileSheet: false,
      selectedFile: {},
      isLoading: false,
      selectedProcess: {},
      checkList: [],
      docWarning: false,
      rowIndex: null,
      processActions: [],
      processActionsAddOn: [],
      actionText: "",
      actionRemarks: false,
      actionPassword: false,
      actionPasswordConfirm: false,
      actionUserPassword: "",
      actionComments: false,
      actionConfirm: false,
      actionConfirmAlert: false,
      workflowFormModel: {},
      saveAction: "",
      generatePDF: 0,
      successModal: false,
      alertModal: false,
      formEditAccess: false,
      formEditControls: [],
      readonlyControls: [],
      formVisibilityAccess: true,
      formSecureControls: [],
      userSignature: false,
      customReply: false,
      processForwardModal: false,
      internalForward: {
        proceed: false,
        users: [],
        groups: [],
      },
      userList: [],
      groupList: [],
      forwardUsers: [],
      forwardGroups: [],
      documentActionList: [
        // {
        //   id: "Email_Sharing",
        //   key: "emailSharing",
        //   label: "Email Sharing",
        //   icon: "mdi-email-outline",
        // },
        {
          id: "Download",
          key: "download",
          label: "Download",
          icon: "mdi-download-outline",
        },
        {
          id: "Print",
          key: "print",
          label: "Print",
          icon: "eva-printer-outline",
        },
      ],
      repositoryDetails: [],
      repositoryField: [],
      showMetaDataEditModal: false,
      selectedUploadFile: {},
      uploadFilename: "",
      fileData: [],
      workflowModel: {},
      historyProcessId: 0,
      formUploadFiles: [],
      mandatory: {},
      fileControlDeleteFile: [],
      hasCheckFormValidate: false,
      formFields: [],
      subWorkflowFormList: [],
      fileUploadForControl: false,
      fileUploadInput: {},
      metaDataModal: {},
      metaDataTitle: "",
      formView: false,
      portalMasterFormId: 0,
      portalSettingJson: {},
      masterFormFields: [],
      fileUploadRequired: false,
      mandatoryDocument: [],
      ocrResult: [],
      requestFormData: {},
      watchViewerClose: null,
    };
  },

  computed: {
    ...mapState(["darkTheme"]),
    panels() {
      if (!this.formId) {
        return [];
      }

      return this.form.panels;
    },

    secondaryPanels() {
      if (!this.formId) {
        return [];
      }

      return this.form.secondaryPanels;
    },

    formSettings() {
      if (!this.formId) {
        return {};
      }
      return this.form.settings;
    },

    checkListTemplate() {
      if (this.checkList.length) {
        return this.checkList.filter((row) => row.template).length > 0;
      }
      return false;
    },

    _enableControls() {
      let enableControls = [];
      let listControls = [];
      this.panels.forEach((panel) => {
        if (panel.fields.length) {
          let panelFields = panel.fields.filter(
            (field) =>
              field.type === "SINGLE_SELECT" ||
              field.type === "SINGLE_CHOICE" ||
              field.type === "MULTIPLE_CHOICE" ||
              field.type === "MULTI_SELECT"
          );
          if (panelFields.length) {
            panelFields.forEach((row) => {
              if (row.settings.validation.enableSettings) {
                if (row.settings.validation.enableSettings.length) {
                  let controls = [];
                  row.settings.validation.enableSettings.forEach((tblrow) => {
                    if (tblrow.controls.length) {
                      controls.push({
                        value: tblrow.value,
                        childControls: tblrow.controls,
                      });
                      tblrow.controls.forEach((control) => {
                        if (controls.indexOf(control) < 0) {
                          listControls.push(control);
                        }
                      });
                    }
                  });
                  if (controls.length) {
                    enableControls.push({
                      id: row.id,
                      parentControls: controls,
                    });
                  }
                }
              }
            });
          }
        }
      });
      return {
        listControls: listControls,
        controls: enableControls,
      };
    },

    _columns() {
      let tableColumns = [];
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (
            field.type !== "DIVIDER" &&
            field.type !== "LABEL" &&
            field.type !== "PARAGRAPH" &&
            (field.label || field.type === "TABLE") &&
            field.settings.general.visibility !== "DISABLE"
          ) {
            tableColumns.push({
              id: this.$nano.id(),
              name: field.id,
              label: field.label,
              type: field.type,
              isVisible: true,
              isSortable: true,
              isGroupable: false,
            });
          }
        }
      });

      return tableColumns;
    },

    mandatoryFilling() {
      if (this.mandatory.filledValue && this.mandatory.nonFilledValue) {
        return Math.floor(
          (this.mandatory.filledValue /
            (this.mandatory.filledValue + this.mandatory.nonFilledValue)) *
            100
        );
      } else if (this.mandatory.filledValue) {
        return 100;
      }
      return 0;
    },
  },

  watch: {
    userId: {
      immediate: true,
      handler: "getSession",
    },

    workflowFormModel: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workflowFormModel && this.saveAction) {
          this.checkRequiredDocuments();
        }
      },
    },

    saveAction: {
      immediate: true,
      handler() {
        if (this.saveAction && !this.formView) {
          this.workflowFormModel = this.selectedProcess.formData.fields;
          this.checkRequiredDocuments();
        }
      },
    },

    checkList: {
      deep: true,
      immediate: true,
      handler() {
        if (this.checkList.length) {
          let list = [];
          this.checkList.forEach((row) => {
            // console.log(row);
            if (row.required && !row.attach) {
              list.push(row);
            }
          });
          this.mandatoryDocument = list;
          // console.log(list);
        }
      },
    },

    fileUploadRequired: {
      immediate: true,
      handler() {
        if (this.checkList.length) {
          let list = [];
          this.checkList.forEach((row) => {
            // console.log(row);
            if (row.required && !row.attach) {
              list.push(row);
            }
          });
          // console.log(list);
          this.mandatoryDocument = list;
        }
      },
    },

    attachmentSheet: {
      immediate: true,
      handler() {
        if (this.attachmentSheet && this.selectedProcess.processId) {
          if (this.selectedProcess.flowStatus === "0") {
            this.attachmentLoad = setInterval(() => {
              this.getAttachments();
            }, 15000);
          }
        } else {
          clearInterval(this.attachmentLoad);
        }
      },
    },

    commentsSheet: {
      immediate: true,
      handler() {
        if (this.commentsSheet && this.selectedProcess.processId) {
          this.commentsLoad = setInterval(() => {
            this.getComments();
          }, 30000);
        } else {
          clearInterval(this.commentsLoad);
        }
      },
    },
  },

  methods: {
    checkDocument() {
      this.docWarning = false;
      if (this.checkList.length) {
        this.checkList.forEach((row) => {
          if (row.required && !row.attach) {
            this.docWarning = true;
          }
        });
      }
    },
    checkRequiredDocuments() {
      let docRequired = [];
      docRequired = this.checkList.filter((row) => row.required && !row.attach);
      if (docRequired.length) {
        if (this.docWarning) {
          this.alertModal = true;
        } else {
          this.$alert.warning("Mandatory documents required");
          this.saveAction = "";
          this.workflowFormModel = {};
        }
      } else {
        this.processRequest();
      }
    },

    async showLinks(tab) {
      if (tab === "ATTACHMENTS") {
        this.attachmentList = [];
        this.attachmentSheet = true;
        await this.getAttachments();
      } else if (tab === "COMMENTS") {
        this.commentsList = [];
        this.commentsSheet = true;
        await this.getComments();
      } else if (tab === "HISTORY") {
        this.historyProcessId = this.selectedProcess.processId;
      }
    },

    closeAlertModal() {
      this.saveAction = "";
      this.workflowFormModel = {};
      this.alertModal = false;
    },

    closeSuccessModal() {
      location.reload();
    },

    lastCommentDuration(comment) {
      const date =
        comment.createdAt instanceof Date
          ? comment.createdAt
          : new Date(comment.createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    fileSize(size) {
      return formatBytes(size);
    },

    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (this.selectedFile.name) {
        let ext = this.selectedFile.name.split(".").pop();
        if (this.selectedFile.initiate) {
          if (pdfSupport(ext)) {
            if (
              origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net"
            ) {
              return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            } else if (origin === "https://trial.ezofis.com") {
              return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            } else if (origin === "https://app.ezofis.com1") {
              return `https://ezdocsviewer.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            } else {
              return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            }
          } else if (origin === "http://52.172.32.88") {
            return `http://52.172.32.88/DocsViewer/index.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
          } else {
            return `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/1`;
          }
        } else {
          if (pdfSupport(ext)) {
            if (
              origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net"
            ) {
              return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}`;
            } else if (
              origin === "https://trial.ezofis.com" &&
              !otherFilePDFSupport(ext)
            ) {
              return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&wId=${this.workflow.id}&pId=${this.selectedProcess.processId}&type=2&filename=${this.selectedFile.name}&action=download,print`;
            } else if (origin === "https://app.ezofis.com1") {
              return `https://ezdocsviewer.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&wId=${this.workflow.id}&pId=${this.selectedProcess.processId}&type=2&filename=${this.selectedFile.name}&action=download,print`;
            } else {
              return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&wId=${this.workflow.id}&pId=${this.selectedProcess.processId}&type=2&filename=${this.selectedFile.name}&action=download,print`;
            }
          } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
            return `http://52.172.32.88/DocsViewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}`;
          } else {
            return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
          }
        }
      }
    },

    openFile(fileObj) {
      this.selectedFile = fileObj;
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        //window.open(this.fileLink());
      } else {
        this.fileSheet = true;
      }
    },

    download(id) {
      window.open(
        `${process.env.VUE_APP_API_URL}/workflow/viewChecklist?wId=${this.workflowId}&id=${id}`
      );
    },

    downloadAll() {
      this.checkList.forEach((row) => {
        if (row.id && row.template) {
          window.open(
            `${process.env.VUE_APP_API_URL}/workflow/viewChecklist?wId=${this.workflowId}&id=${row.id}`
          );
        }
      });
    },

    attachTemplate(uid) {
      this.rowIndex = uid;
      this.$refs.attachments.click();
    },

    lastActionDuration(request) {
      const date =
        request.transaction_createdAt instanceof Date
          ? request.transaction_createdAt
          : new Date(request.transaction_createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    fileMenuAction(fileObj, action) {
      this.selectedFile = fileObj;
      this.documentAction(action);
    },

    documentAction(action) {
      if (action === "Email_Sharing") {
        //
      } else if (action === "Print") {
        if (this.selectedFile.name) {
          let ext = this.selectedFile.name.split(".").pop();
          if (pdfSupport(ext)) {
            localStorage.setItem("filePrint", true);
            localStorage.setItem("filePrintId", this.selectedFile.id);
          } else {
            this.$refs.myFiles.contentWindow.print();
          }
        }
        this.filePrintSession();
      } else if (action === "Download") {
        let link = "";
        link = `${process.env.VUE_APP_API_URL}/menu/file/download/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
        window.open(link, "_blank");
        //this.$alert.success(`file downloaded successfully`);
      }
    },

    mandatoryControlsList(value) {
      this.mandatory = value;
    },

    deletedFiles(ids, files) {
      this.fileControlDeleteFile.push(ids);

      if (files) {
        console.log(files);
        if (files.controls.length) {
          files.controls.forEach((field) => {
            // console.log(field);
            this.workflowModel[field] = "";
          });
        }

        let hasFile = this.attachmentList.findIndex((file) => {
          return (
            file.name === files.file.name &&
            file.id === files.file.fileId &&
            file.fileControl
          );
        });
        // console.log(hasFile);
        if (hasFile >= 0) {
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length - 1;
            }
          });
          this.attachmentList.splice(hasFile, 1);
        }
      }
    },

    checkFormValidation() {
      this.hasCheckFormValidate = true;
    },

    validateType(key) {
      let fieldType = "";
      this.form.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        let formField = panel.fields.find((field) => field.id === key);
        if (formField) {
          fieldType = formField.type;
        }
      });
      return fieldType;
    },

    getLabel(id, type) {
      let controlLabel = "";
      this.form.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (type === "table") {
            const column = field.settings.specific.tableColumns.find(
              (column) => column.id === id
            );
            if (column) {
              controlLabel = column.label;
            }
          } else {
            if (field.id === id && field.settings.general.hideLabel === false) {
              controlLabel = field.label;
              return;
            }
          }
        }
      });
      return controlLabel;
    },

    validateValue(value) {
      let urlPattern =
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      return !urlPattern.test(value);
    },

    removeTags(value) {
      if (!value) return "";
      return value.replace(/(<([^>]+)>)/gi, "");
    },

    tableValue(value) {
      if (value) {
        return JSON.parse(value);
      }
      return [];
    },

    validateTypeValue(value) {
      if (value) {
        try {
          var jsonConvert = JSON.parse(value);
          if (typeof jsonConvert === "object") {
            return jsonConvert;
          }
        } catch (e) {
          return value;
        }
      }
      return value;
    },

    transformFormDataToPanelsArray(formData, panels) {
      const transformedFormData = {
        formId: formData.formId,
        formEntryId: formData.formEntryId,
        panels: [],
      };

      panels.forEach((panel, index) => {
        const panelTitle = panel.settings.title;
        const panelFields = panel.fields;

        const panelData = {
          name: panelTitle,
          panelsExpandView: index === 0,
          fields: {},
        };

        panelFields.forEach((field) => {
          const fieldId = field.id;
          const fieldValue = formData.fields[fieldId] || "";
          panelData.fields[fieldId] = fieldValue;
        });

        transformedFormData.panels.push(panelData);
      });

      return transformedFormData;
    },

    itemLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      let domainURL = "";
      if (origin === "https://trial.ezofis.com") {
        domainURL = "https://trial.ezofis.com/docsviewer/index.html";
      } else if (origin === "http://52.172.32.88") {
        domainURL = `http://52.172.32.88/DocsViewer/index.html`;
      } else {
        domainURL = `${origin}/PDFViewer/web/viewer.html`;
      }
      if (
        this.workflow.initiatedBy === "DOCUMENT_SIGN" &&
        this.selectedProcess.activityUserEmail ===
          this.$store.state.session.email
      ) {
        localStorage.setItem("signItemId", 0);
        localStorage.setItem("viewerClose", false);
        this.watchViewerClose = setInterval(
          () => this.checkViewerClose(),
          1000
        );
        return `${domainURL}?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedProcess.itemData.repositoryId}&itemId=${this.selectedProcess.itemData.itemId}&wId=${this.workflow.id}&pId=${this.selectedProcess.processId}&type=2&signature=true&getSign=true&uniqId=${this.selectedProcess.raisedByUserId}`;
      }

      return `${domainURL}?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedProcess.itemData.repositoryId}&itemId=${this.selectedProcess.itemData.itemId}&wId=${this.workflow.id}&pId=${this.selectedProcess.processId}&type=2`;
    },

    async validatePassword() {
      const areAllFieldsValid = await this.$refs.passwordForm.validate();

      if (!areAllFieldsValid) {
        return;
      }
      this.isLoading = true;
      const { error } = await auth.validatePassword({
        password: this.actionUserPassword,
      });
      this.isLoading = false;
      if (error) {
        console.log(error);
        this.$alert.warning(error);
        return;
      }
      this.actionUserPassword = "";
      this.actionPasswordConfirm = false;
      if (this.actionRemarks) {
        this.actionComments = true;
        this.advancedCommentsSheet = true;
      } else {
        this.actionRemarks = false;
        this.actionPassword = false;
        this.processRequest(false, false);
      }
    },

    async getSession() {
      const { error } = await auth.externalLogin(this.tenantId, this.userId);
      if (error) {
        this.$alert.info("User Account Not Found");
        return;
      }
      this.getProcess();
    },

    async getProcess() {
      this.$store.commit("showLoadingBar");
      let filters = [];
      if (this.userId.indexOf("ES") > 0) {
        filters = [
          {
            filters: [
              {
                criteria: "processId",
                condition: "IS_EQUALS_TO",
                value: this.processId,
              },
            ],
          },
        ];
      } else {
        filters = [
          {
            filters: [
              {
                criteria: "processId",
                condition: "IS_EQUALS_TO",
                value: this.processId,
              },
              {
                criteria: "actionUser",
                condition: "IS_EQUALS_TO",
                value: this.userId,
              },
            ],
          },
        ];
      }
      const { error, payload } = await report.getWorkflowData(this.workflowId, {
        filterBy: filters,
        itemsPerPage: 10,
        currentPage: 1,
      });
      if (error) {
        this.$store.commit("hideLoadingBar");
        this.$alert.error(error);
        return;
      }
      if (payload.meta.totalItems) {
        if (payload.data.length) {
          if (payload.data[0].value.length) {
            this.selectedProcess = payload.data[0].value[0];
            this.title = this.selectedProcess.requestNo;
            console.log(this.selectedProcess);
            if (this.selectedProcess.formData) {
              let keys = Object.keys(this.selectedProcess.formData.fields);
              let newValues = {};
              keys.forEach((key) => {
                newValues[key] = this.validateTypeValue(
                  this.selectedProcess.formData.fields[key]
                );
              });
              this.formModel = newValues;
            }
            this.tabs.find((tab) => {
              if (tab.value === "COMMENTS") {
                tab.count = this.selectedProcess.commentsCount;
              }
            });
            this.tabs.find((tab) => {
              if (tab.value === "ATTACHMENTS") {
                tab.count = this.selectedProcess.attachmentCount;
              }
            });
            this.getWorkflow();
          }
        }
      }
    },

    async getWorkflow() {
      const { error, payload } = await workflow.getWorkflow(this.workflowId);
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.workflow = payload;
      let workflowJson = JSON.parse(this.workflow.flowJson);
      this.processActions = [];
      this.processActionsAddOn = [];
      workflowJson.rules.forEach((rule) => {
        if (rule.fromBlockId === this.selectedProcess.activityId) {
          if (this.processActions.indexOf(rule.proceedAction) < 0) {
            this.processActions.push(rule.proceedAction);
            this.processActionsAddOn.push({
              action: rule.proceedAction,
              remarks: rule.remarks || false,
              passwordAccess: rule.passwordAccess || false,
              confirm: rule.confirm || false,
            });
          }
        }
      });
      if (this.workflow.initiatedBy === "DOCUMENT_SIGN") {
        this.loadingBarContent = false;
        return;
      }
      if (workflowJson.settings.general.initiateUsing.formId) {
        this.getForm(workflowJson.settings.general.initiateUsing.formId);
      }
      this.description = `${this.workflow.name} - ${this.selectedProcess.stage}`;
      this.formEditAccess = true;
      if (
        this.selectedProcess.activityUserEmail ===
        this.$store.state.session.email
      ) {
        let block = workflowJson.blocks.find(
          (block) => block.id === this.selectedProcess.activityId
        );
        if (block) {
          if (
            this.selectedProcess.lastAction === "Forward" &&
            block.settings.forwardedUserAction === "REPLY"
          ) {
            this.processActions.push(
              "Reply to " + this.selectedProcess.transaction_createdByEmail
            );
            this.processActionsAddOn.push({
              action:
                "Reply to " + this.selectedProcess.transaction_createdByEmail,
              remarks: true,
              passwordAccess: false,
              confirm: false,
            });
            this.customReply = true;
          } else {
            if (
              block.settings.userReply &&
              this.processActions.indexOf("Reply") === -1
            ) {
              this.processActions.push(
                "Reply to " + this.selectedProcess.lastActionStageName
              );
              this.userReply = true;
            }
            if (
              block.settings.toInitiator &&
              this.processActions.indexOf("To Requester") === -1
            ) {
              this.processActions.push("To Requester");
              this.toRequester = true;
            }
            if (block.settings.internalForward) {
              this.processActions.push("Forward");
              this.internalForward.proceed = true;
              this.internalForward.users = [];
              this.internalForward.groups = [];
              if (block.settings.internalForwardUser) {
                if (block.settings.internalForwardUser.length) {
                  block.settings.internalForwardUser.forEach((user) => {
                    let userFilter = this.userList.filter(
                      (row) => row.value == user
                    );
                    if (userFilter.length) {
                      this.internalForward.users.push({
                        id: this.$nano.id(),
                        label: userFilter[0].label,
                        value: user,
                      });
                    }
                  });
                }
              } else {
                this.getUsers();
              }
              if (block.settings.internalForwardGroup) {
                if (block.settings.internalForwardGroup.length) {
                  block.settings.internalForwardGroup.forEach((group) => {
                    let groupFilter = this.groupList.filter(
                      (row) => row.value == group
                    );
                    if (groupFilter.length) {
                      this.internalForward.groups.push({
                        id: this.$nano.id(),
                        label: groupFilter[0].label,
                        value: group,
                      });
                    }
                  });
                }
              } else {
                this.getGroups();
              }
            }
          }

          this.userSignature = block.settings.userSignature;
          if (block.settings.fileSettings) {
            if (block.settings.fileSettings.fileCheckList.length) {
              this.checkList = block.settings.fileSettings.fileCheckList;
              this.checkList.forEach((row) => {
                row.uid = this.$nano.id();
                row.attach = false;
                this.fileUploadRequired = !this.fileUploadRequired;
              });
              this.docWarning = block.settings.fileSettings.partial;
            }
          }

          if (block.settings.formEditAccess) {
            if (block.settings.formEditAccess === "") {
              this.formEditAccess = true;
              this.formEditControls = [];
            } else if (block.settings.formEditAccess === "FULL") {
              this.formEditAccess = false;
              this.formEditControls = [];
            } else if (block.settings.formEditAccess === "PARTIAL") {
              this.formEditAccess = false;
              this.formEditControls = block.settings.formEditControls;
            }
          }
          if (block.settings.formVisibilityAccess) {
            this.formVisibilityAccess = true;
            this.formSecureControls = [];
          } else {
            this.formVisibilityAccess = false;
            this.formSecureControls = block.settings.formSecureControls;
          }
          if (
            workflowJson.settings.general.processNumberPrefix.indexOf("[") > -1
          ) {
            let requestNumberFormat = JSON.parse(
              workflowJson.settings.general.processNumberPrefix
            );
            this.readonlyControls = [];
            let requestColumn = requestNumberFormat.filter(
              (row) => row.key === "formColumn"
            );
            if (requestColumn.length) {
              requestColumn.forEach((row) => {
                this.readonlyControls.push(row.value);
                this.formEditControls.splice(
                  this.formEditControls.indexOf(row.value),
                  1
                );
              });
            }
          }
        }
      }
      if (this.workflow.repositoryId) {
        this.getRepository();
      }
    },

    async getForm(fId) {
      const { error, payload } = await form.getForm(fId);
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.form = JSON.parse(payload.formJson);
      this.formId = fId.toString();

      const panels = [...this.form.panels, ...this.form.secondaryPanels];

      if (!panels.length) {
        return;
      }
      let fields = [];
      for (const panel of panels) {
        fields.push(...panel.fields);
      }

      fields.forEach((field) => {
        if (
          field.type !== "DIVIDER" &&
          field.type !== "LABEL" &&
          field.type !== "PARAGRAPH" &&
          (field.label || field.type === "TABLE") &&
          field.settings.general.visibility !== "DISABLE"
        ) {
          this.formFields.push({
            id: this.$nano.id(),
            label: field.label || field.type,
            value: field.id,
            type: field.type,
            settings: field.settings,
          });
        }
      });
      this.requestFormData = await this.transformFormDataToPanelsArray(
        this.selectedProcess.formData,
        this.form.panels
      );
      this.loadingBarContent = false;
    },

    async getRepository() {
      const { error, payload } = await repository.getRepository(
        this.workflow.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.repositoryDetails = payload;
      this.repositoryField = payload.fields;
    },

    async getUsers() {
      this.userList = [];
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }
      if (payload) {
        payload.map((user) => {
          if (user.id !== this.$store.state.session.id)
            this.userList.push({
              id: this.$nano.id(),
              label: user.value,
              value: user.id,
            });
        });
      }
    },

    async getGroups() {
      const { error, payload } = await group.getGroupList();

      if (error) {
        this.$alert.error("Error fetching groups");
        return;
      }

      this.groupList =
        payload &&
        payload.map((group) => ({
          id: this.$nano.id(),
          label: group.value,
          value: group.id,
        }));
    },

    saveMetaData(modal) {
      let data = [];
      this.repositoryField.forEach((field) => {
        data.push({
          name: field.name,
          value: modal[field.name],
          type: field.dataType,
        });
      });

      data.forEach((key) => {
        let hasName = this.formFields.find(
          (item) => lowerCase(item.label) === lowerCase(key.name)
        );
        if (hasName && key.value) {
          this.workflowModel[hasName.value] = key.value;
        }
      });

      if (this.fileUploadForControl) {
        this.metaDataModal = modal;
        let input = this.fileUploadInput;
        this.uploadFilesFromForm(
          input.files,
          input.control,
          input.fieldId,
          JSON.stringify(data)
        );
      } else {
        this.uploadAttachments(this.selectedUploadFile, JSON.stringify(data));
      }
    },

    closeMetaDataEditModal() {
      this.showMetaDataEditModal = false;
      if (this.$refs.attachments) {
        this.$refs.attachments.value = null;
      }
    },

    async getComments() {
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = 0;
        }
      });
      this.commentsList = [];
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getProcessComments(
        this.workflow.id,
        this.selectedProcess.processId
      );

      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.commentsList = payload;
        this.tabs.find((tab) => {
          if (tab.value === "COMMENTS") {
            tab.count = this.commentsList.length;
          }
        });
      }
    },

    async saveComment() {
      if (this.commentText) {
        if (this.selectedProcess.processId) {
          this.$store.commit("showLoadingBar");
          const { error } = await workflow.insertProcessComment(
            this.workflow.id,
            this.selectedProcess.processId,
            this.selectedProcess.transactionId,
            {
              comments: this.commentText,
              showTo: this.commentAction,
            }
          );

          this.$store.commit("hideLoadingBar");

          if (error) {
            this.$alert.error(error);
            return;
          }
          this.commentText = "";
          this.getComments();
        } else {
          this.commentsList.push({
            comments: this.commentText,
            createdAt: new Date().toISOString(),
            createdBy: this.$store.state.session.id,
          });
          this.tabs.find((tab) => {
            if (tab.value === "COMMENTS") {
              tab.count = this.commentsList.length;
            }
          });
          this.commentText = "";
        }
      }
    },

    async saveAdvancedComment() {
      if (this.advancedCommentText) {
        if (this.selectedProcess.processId) {
          this.$store.commit("showLoadingBar");
          const { error } = await workflow.insertProcessComment(
            this.workflow.id,
            this.selectedProcess.processId,
            this.selectedProcess.transactionId,
            {
              comments: this.advancedCommentText,
              showTo: this.commentAction,
            }
          );

          this.$store.commit("hideLoadingBar");

          if (error) {
            this.$alert.error(error);
            return;
          }
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
          this.getComments();
        } else {
          this.commentsList.push({
            comments: this.advancedCommentText,
            createdAt: new Date().toISOString(),
            createdBy: this.$store.state.session.id,
          });
          this.tabs.find((tab) => {
            if (tab.value === "COMMENTS") {
              tab.count = this.commentsList.length;
            }
          });
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
        }
        if (this.actionComments) {
          this.processRequest(false, false);
        }
      }
    },

    async uploadWflowAttachments(e) {
      this.fileUploadForControl = false;
      if (this.repositoryDetails.fieldsType === "STATIC") {
        this.selectedUploadFile = e;
        this.uploadFilename = e.target.files[0].name;
        this.showMetaDataEditModal = true;
      } else {
        this.uploadAttachments(e);
      }
    },

    async uploadAttachments(e, fields) {
      if (this.selectedProcess.processId) {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("workflowId", this.workflow.id);
        formData.append("repositoryId", this.workflow.repositoryId);
        formData.append("processId", this.selectedProcess.processId);
        formData.append("transactionId", this.selectedProcess.transactionId);
        formData.append("fields", "");
        formData.append("file", file);
        let filename = e.target.files[0].name;
        const ext = filename.split(".").pop();
        if (this.rowIndex) {
          let currentDoc = this.checkList.filter(
            (row) => row.uid === this.rowIndex
          );
          formData.append("filename", currentDoc[0].name + "." + ext);
          filename = currentDoc[0].name + "." + ext;
        }
        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        this.$store.commit("showLoadingBar");

        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/workflow/attachmentWithProcessId`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          const { status, data } = response;
          e.target.value = "";
          if (status === 200 && data) this.$alert.success(`Document attached`);
          else throw response;
        } catch (error) {
          this.$alert.error("Error uploading file");
          console.error(error);
        }
        this.$store.commit("hideLoadingBar");
        if (this.checkList.length) {
          this.checkList.forEach((row) => {
            if (row.uid === this.rowIndex) {
              row.attach = true;
              this.fileUploadRequired = !this.fileUploadRequired;
              return;
            }
          });
          //this.checkDocument();
        }
        e.target.value = "";
      } else {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("repositoryId", this.workflow.repositoryId);
        if (fields) {
          this.fileData = JSON.parse(fields);
          formData.append("fields", fields);
        }
        let filename = e.target.files[0].name;
        const ext = filename.split(".").pop();
        if (this.rowIndex) {
          let currentDoc = this.checkList.filter(
            (row) => row.uid === this.rowIndex
          );
          formData.append("filename", currentDoc[0].name + "." + ext);
          filename = currentDoc[0].name + "." + ext;
        }
        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        this.$store.commit("showLoadingBar");
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          this.$store.commit("hideLoadingBar");
          const { status, data } = response;
          e.target.value = "";
          if (status !== 200) {
            throw "Invalid response";
          }
          let filesList = JSON.parse(data);
          this.attachmentList.push({
            id: Number(filesList.fileId),
            name: filename,
            createdAt: new Date().toISOString(),
            createdByEmail: this.$store.state.session.email,
            size: file.size,
            initiate: true,
            uid: this.rowIndex,
          });
          this.$alert.success(`Document attached`);
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length;
            }
          });
          if (this.checkList.length) {
            this.checkList.forEach((row) => {
              if (row.uid === this.rowIndex) {
                row.attach = true;
                this.fileUploadRequired = !this.fileUploadRequired;
                return;
              }
            });
            //this.checkDocument();
          }
        } catch (error) {
          console.error(error);
          this.$alert.error(`error attaching file`);
        }
      }
    },

    async getAttachments() {
      this.tabs.find((tab) => {
        if (tab.value === "ATTACHMENTS") {
          tab.count = 0;
        }
      });
      this.checkList = [];
      if (this.selectedProcess.processId) {
        let workflowJson = JSON.parse(this.workflow.flowJson);
        let block = workflowJson.blocks.find(
          (block) => block.id === this.selectedProcess.activityId
        );
        if (block) {
          if (block.settings.fileSettings) {
            if (block.settings.fileSettings.fileCheckList.length) {
              this.checkList = block.settings.fileSettings.fileCheckList;
              this.checkList.forEach((row) => {
                row.uid = this.$nano.id();
                row.attach = false;
                this.fileUploadRequired = !this.fileUploadRequired;
              });
              //this.checkDocument();
            }
          }
        }
        let count = this.attachmentList.length;
        this.$store.commit("showLoadingBar");
        const { error, payload } = await workflow.getAttachments(
          this.workflow.id,
          this.selectedProcess.processId
        );

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
        if (payload.length) {
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = payload.length;
            }
          });
          if (count !== payload.length) {
            this.attachmentList = payload.map((item) => {
              return {
                ...item,
                checked: false,
              };
            });
          }
        } else {
          this.attachmentList = [];
        }
        if (this.checkList.length) {
          this.checkList.forEach((row) => {
            let filterAttach = this.attachmentList.filter((file) => {
              let filename = file.name.substr(0, file.name.lastIndexOf("."));
              if (row.name === filename) {
                return file;
              }
            });
            if (filterAttach.length) {
              row.attach = true;
            } else {
              row.attach = false;
            }
            this.fileUploadRequired = !this.fileUploadRequired;
          });
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length;
            }
          });
        }
      }
    },

    async attachmentDelete(file) {
      if (!file.fileControl) {
        this.$store.commit("showLoadingBar");
        if (file.initiate) {
          const { error } = await uploadAndIndex.deleteUploadedFiles([file.id]);
          if (error) {
            this.$alert.error(error);
            return;
          }
          this.$store.commit("hideLoadingBar");
          this.attachmentList = this.attachmentList.filter(
            (row) => row.id !== file.id
          );
          this.checkList.forEach((row) => {
            if (row.uid === file.uid) {
              row.attach = false;
              this.fileUploadRequired = !this.fileUploadRequired;
            }
          });
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length;
            }
          });
        } else {
          const { error } = await repository.fileDelete(
            this.workflow.repositoryId,
            file.id
          );
          if (error) {
            this.$alert.error(error);
            return;
          }
          this.$store.commit("hideLoadingBar");
          this.getAttachments();
        }
      } else {
        let field = this.workflowModel[file.fieldId];
        if (field.length) {
          let hasField = field.findIndex((item) => {
            return item.fileId === file.id;
          });
          if (hasField >= 0) {
            this.workflowModel[file.fieldId].splice(hasField, 1);
          }

          let hasValue = this.formUploadFiles.findIndex((item) => {
            return item.fileId === file.id && item.fileId === file.fileId;
          });
          if (hasValue >= 0) {
            this.formUploadFiles.splice(hasField, 1);
          }
          if (file.control.length) {
            file.control.forEach((item) => {
              this.workflowModel[item] = "";
            });
          }
        }
        let hasFile = this.attachmentList.findIndex((file) => {
          return file.fileControl;
        });
        if (hasFile >= 0) {
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length - 1;
            }
          });
          this.attachmentList.splice(hasFile, 1);
        }
      }
    },

    async getOCRResult(files, control, fieldId) {
      const formData = new FormData();
      let formFields = [];
      if (control && control.length) {
        control.forEach((con) => {
          this.readonlyControls.push(con);
        });
        control.forEach((field) => {
          let name = this.formFields.find((item) => item.value === field);
          if (name) {
            formFields.push(`${name.label}, ${name.type}`);
          }
        });
        if (files.length) {
          //this.$store.commit("showLoadingBarSave");
          for (let file of files) {
            if (file.name && !file.error) {
              formData.append("repositoryId", this.workflow.repositoryId);
              formData.append("file", file);
              if (formFields.length) {
                formData.append("formFields", JSON.stringify(formFields));
              }

              try {
                const response = await axios({
                  url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/uploadforStaticMetadata`,
                  method: "POST",
                  data: formData,
                  headers: {
                    Token: this.$store.state.identity.token,
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                  },
                  onUploadProgress: function (progressEvent) {
                    let uploadedPercentage = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    // console.log(this.workflowModel, fieldId);

                    let hasFile = this.workflowModel[fieldId].findIndex(
                      (item) => {
                        return item.name === file.name;
                      }
                    );
                    // console.log(hasFile);
                    if (hasFile >= 0) {
                      this.$set(
                        this.workflowModel[fieldId][hasFile],
                        "uploadedPercentage",
                        0
                      );
                      if (uploadedPercentage < 50) {
                        this.workflowModel[fieldId][
                          hasFile
                        ].uploadedPercentage = uploadedPercentage;
                      } else {
                        this.workflowModel[fieldId][
                          hasFile
                        ].uploadedPercentage = 49;
                      }
                    }
                  }.bind(this),
                });

                const { status, data } = response;
                if (status !== 200) {
                  throw "Invalid response";
                }

                console.log(data);

                let list = decrypt(
                  data,
                  this.$store.state.identity.key,
                  this.$store.state.identity.iv
                );
                console.log(list);

                let ocrList = JSON.parse(list);
                let ocr = JSON.parse(ocrList);
                let ocrFile = JSON.parse(ocr);

                if (Array.isArray(ocrFile)) {
                  ocrFile.forEach((hasFile) => {
                    let fieldId = this.formFields.find(
                      (item) => item.label === hasFile.name
                    );
                    if (fieldId) {
                      if (hasFile.value) {
                        this.workflowModel[fieldId.value] = hasFile.value;
                      }
                    }
                  });
                  this.ocrResult = ocrFile;
                }
              } catch (error) {
                console.error(error);
                this.$alert.error(`error on get ocr result`);
              }
            }
            let hasFile = this.workflowModel[fieldId].findIndex((item) => {
              return item.name === file.name;
            });

            // console.log(hasFile);

            if (hasFile >= 0) {
              //this.workflowModel[fieldId][hasFile].uploadedPercentage = 100;
            }
          }

          this.$store.commit("hideLoadingBarSave");
          this.uploadFileForControl(files, control, fieldId);
        }
      } else {
        this.uploadFileForControl(files, control, fieldId);
      }
    },

    async uploadFilesFromForm(files, control, fieldId, fields) {
      this.formUploadFiles = [];
      const formData = new FormData();
      if (files.length) {
        //this.$store.commit("showLoadingBarSave");
        for (let file of files) {
          if (file.name && !file.error) {
            formData.append("repositoryId", this.workflow.repositoryId);
            formData.append("file", file);
            formData.append("filename", file.name);
            if (fields) {
              formData.append("fields", fields);
            } else {
              formData.append("fields", "");
            }

            try {
              const response = await axios({
                url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
                method: "POST",
                data: formData,
                headers: {
                  Token: this.$store.state.identity.token,
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: function (progressEvent) {
                  let uploadedPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  // console.log(this.workflowModel, fieldId);
                  let hasFile = this.workflowModel[fieldId].findIndex(
                    (item) => {
                      return item.name === file.name;
                    }
                  );
                  // console.log(hasFile);
                  if (hasFile >= 0) {
                    this.$set(
                      this.workflowModel[fieldId][hasFile],
                      "uploadedPercentage",
                      0
                    );
                    if (uploadedPercentage > 50 && uploadedPercentage !== 100) {
                      this.workflowModel[fieldId][hasFile].uploadedPercentage =
                        uploadedPercentage;
                    } else {
                      this.workflowModel[fieldId][
                        hasFile
                      ].uploadedPercentage = 98;
                    }
                  }
                }.bind(this),
              });

              const { status, data } = response;
              if (status !== 200) {
                throw "Invalid response";
              }
              file.createdAt = this.$day.newDateTime();
              file.createdBy = this.$store.state.session.email;
              let filesList = JSON.parse(data);
              file.fileId = filesList.fileId;
              file.fieldId = fieldId;
              this.attachmentList.push({
                id: Number(filesList.fileId),
                name: file.name,
                createdAt: new Date().toISOString(),
                createdByEmail: this.$store.state.session.email,
                size: file.size,
                initiate: true,
                formAttach: true,
                fileControl: true,
                fieldId: fieldId,
                control: control,
              });
              this.tabs.find((tab) => {
                if (tab.value === "ATTACHMENTS") {
                  tab.count = this.attachmentList.length;
                }
              });
            } catch (error) {
              console.error(error);
              this.$alert.error(`error attaching file`);
            }
          }
          let hasFile = this.workflowModel[fieldId].findIndex((item) => {
            return item.name === file.name;
          });

          if (hasFile >= 0) {
            this.workflowModel[fieldId][hasFile].uploadedPercentage = 100;
          }
        }

        let _files = Array.from(files);
        this.formUploadFiles = [];
        for (let file of _files) {
          // console.log(file.name);
          if (file.name) {
            const base64String = await fileToBase64(file);

            this.formUploadFiles.push({
              name: file.name,
              size: file.size,
              file: base64String,
              fileId: file.fileId,
              createdBy: file.createdBy,
              createdAt: file.createdAt,
              workflowId: this.selectedProcess.id,
              processId: this.selectedProcess.processId,
              fieldId: file.fieldId,
            });
          }
        }

        control.forEach((con) => {
          let index = this.readonlyControls.findIndex((item) => {
            return item === con;
          });

          // console.log(index);
          if (index >= 0) {
            this.readonlyControls.splice(index, 1);
          }
        });

        this.$store.commit("hideLoadingBarSave");
      }
    },

    async fileControlDeletedFile(file) {
      this.$store.commit("showLoadingBar");
      let fileIds = [];
      file.forEach((item) => {
        if (item.id) fileIds.push(item.id);
      });
      let input = {
        ids: fileIds,
        formInfo: {
          formId: this.formId,
          formEntryId: this.selectedProcess.formData.formEntryId,
          jsonId: file[0].jsonId,
        },
      };
      const { error } = await repository.fileControlDeleteFile(
        this.workflow.repositoryId,
        input
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async processRequest(forwardCheck = true, addOnCheck = true) {
      if (forwardCheck) {
        if (this.saveAction === "Forward" && this.internalForward.proceed) {
          this.processForwardGroupAction = false;
          this.processForwardModal = true;
          return;
        }
      }

      if (addOnCheck) {
        let actionAddOn = this.processActionsAddOn.find(
          (row) => row.action === this.saveAction
        );
        if (actionAddOn) {
          if (Object.keys(actionAddOn).length) {
            this.actionRemarks = actionAddOn.remarks;
            this.actionPassword = actionAddOn.passwordAccess;
            this.actionConfirm = actionAddOn.confirm;
            if (this.actionPassword) {
              this.actionPasswordConfirm = true;
              return;
            }
            if (this.actionRemarks) {
              this.actionComments = true;
              this.advancedCommentsSheet = true;
              return;
            }
            if (this.actionConfirm) {
              this.actionConfirmAlert = true;
              return;
            }
          }
        }
      }
      let inputData = {};
      if (this.formId) {
        inputData = {
          workflowId: this.workflow.id,
          transactionId: this.selectedProcess.transactionId,
          review: this.saveAction === "Save" ? "" : this.saveAction,
          formData: {
            formId: this.formId,
            formEntryId: this.selectedProcess.formData.formEntryId,
            fields: this.workflowFormModel,
          },
        };
      } else if (this.selectedProcess) {
        inputData = {
          workflowId: this.workflow.id,
          transactionId: this.selectedProcess.transactionId,
          review: "Signed",
          formData: {},
          userIds: this.forwardUsers,
          groupIds: this.forwardGroups,
        };
      }
      this.saveAction = "";
      this.workflowFormModel = {};
      this.loadingBarContent = true;
      const { error } = await workflow.processTransaction(inputData);
      this.loadingBarContent = false;
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (inputData.review === "") {
        this.$alert.info(
          `${this.selectedProcess.requestNo} data saved, awaiting your action to submit`
        );
      } else {
        this.processActions = [];
        this.successModal = true;
      }

      if (this.fileControlDeleteFile.length) {
        this.fileControlDeleteFile.forEach((file) => {
          this.fileControlDeletedFile(file);
        });

        this.fileControlDeleteFile = [];
      }
    },

    checkViewerClose() {
      if (localStorage.getItem("viewerClose")) {
        if (localStorage.getItem("viewerClose") === "true") {
          localStorage.removeItem("viewerClose");
          clearInterval(this.watchViewerClose);
          if (this.workflow.initiatedBy === "DOCUMENT_SIGN") {
            this.getSignerSettings();
          }
        }
      }
    },

    async getSignerSettings() {
      this.forwardUsers = [];
      let itemId = this.selectedProcess.itemData.itemId.toString();
      if (localStorage.getItem("signItemId")) {
        if (localStorage.getItem("signItemId") !== "0") {
          itemId = localStorage.getItem("signItemId");
        }
      }
      localStorage.removeItem("signItemId");
      let inputData = [
        {
          groupCondition: "",
          filters: [
            {
              criteria: "processId",
              condition: "IS_EQUALS_TO",
              value: this.selectedProcess.processId.toString(),
            },
            {
              criteria: "repositoryId",
              condition: "IS_EQUALS_TO",
              value: this.selectedProcess.itemData.repositoryId.toString(),
            },
            {
              criteria: "itemId",
              condition: "IS_EQUALS_TO",
              value: itemId,
            },
          ],
        },
      ];
      try {
        const response = await axios({
          url: `${process.env.VUE_APP_API_URL}/annotation/signSettingsList/${this.$store.state.session.tenantId}/${this.selectedProcess.id}`,
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          data: inputData,
        });

        const { status, data } = response;
        if (status !== 200) {
          throw "Invalid response";
        }
        let signerSettings = JSON.parse(data);
        let signerDet = JSON.parse(signerSettings.settingsJson);
        console.log(signerDet, "signerDet");
        if (signerDet.length) {
          let signer = signerDet.find(
            (row) => row.externalSignerId == this.userId
          );
          if (signer) {
            if (signer.signOrder) {
              let signOrder = signer.signOrder + 1;
              let nextSigner = signerDet.find(
                (row) => row.signOrder === signOrder
              );
              if (nextSigner) {
                if (nextSigner.signerType == "External") {
                  this.forwardUsers.push(nextSigner.externalSignerId);
                } else {
                  this.forwardUsers.push(nextSigner.signerId);
                }
              }
            }
          }
        }
        this.saveAction = this.processActions[0];

        this.processRequest(false, false);
      } catch (error) {
        console.error(error);
        this.$alert.error(`error getting signer details`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.theme-dark {
  #requestDetails {
    table {
      &.submittedData {
        background: #510763 !important;
      }
    }
  }
}
#external-request {
  margin: auto;
  padding: 16px;

  .header {
    padding: 16px;
    border-radius: 4px;
    background-color: var(--component-bg-color);
    border-top: 4px solid var(--primary);
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .description {
      @extend .text-sm;
      color: var(--icon-color-inverted);
    }
  }

  .footer {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    bottom: 16px;
    width: 100%;

    .label {
      @extend .text-sm;
      color: var(--icon-color-inverted);
      margin-right: 4px;
    }

    img {
      height: 16px;
      width: auto;
    }
  }

  .action-footer {
    display: flex;
    justify-content: right;
    width: 100%;
  }

  .border-top {
    border-top: 1px solid var(--divider-color);
  }

  .content {
    border-radius: 4px;
    background-color: var(--component-bg-color);
    height: calc(100vh - 155px);

    .mainMenu {
      padding: 15px;
      border: 1px solid var(--secondary);
      border-radius: 4px;

      .title {
        font-size: 16px;
      }

      .list-item {
        height: 36px;
      }

      .medium {
        font-size: 14px;
      }

      .small {
        font-size: 12px;
      }

      &:hover {
        background-color: $secondary-1;
      }
    }

    .subMenu:hover {
      background-color: $secondary-1;
    }

    .list-item {
      height: 48px;
      border-radius: 4px;
      padding: 16px;
      font-weight: 500;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      user-select: none;
      background-color: rgba(0, 188, 212, 0.15);

      &:hover {
        cursor: pointer;
        color: white;
        background-color: var(--secondary);
      }
    }

    .menuDescription {
      border: 1px solid var(--divider-color);

      .medium {
        height: 160px;
        .justify-center {
          justify-content: normal;
        }
      }
    }

    .menuAction .action {
      margin: auto;
    }
  }

  #requestDetails {
    table {
      &.submittedData {
        background: $primary-1;
      }
      &.mainData {
        table-layout: fixed;
      }
      width: 100%;
      border-radius: 10px;
      border: 1px solid var(--divider-color);

      tr {
        height: 36px;

        th {
          color: $primary-11;
          text-align: left;
        }
      }

      th,
      td {
        padding: 8px;
        font-weight: 500;
      }

      th:not(:last-child),
      td:not(:last-child) {
        border-right: 1px solid var(--divider-color);
      }

      tr:not(:last-child) {
        td,
        th {
          border-bottom: 1px solid var(--divider-color);
        }
      }

      .tblHeader {
        color: $primary-11;
        font-weight: 500;
        &.small {
          width: 200px;
        }
      }
    }

    .ellipsis:hover {
      overflow: visible;
      white-space: normal;
      word-break: break-all;
    }

    .mini-avatar {
      width: 18px;
      height: 18px;
    }

    .text-underline {
      text-decoration: underline;
    }
  }

  .leftBorder {
    border-left: 1px solid var(--divider-color);
  }

  #action-btn.tabButtons {
    height: 52px;
    width: 52px;
  }

  #action-btn.commentAction {
    height: 32px !important;
    width: 32px !important;
  }

  .chip-warning {
    font-weight: 500;
    background-color: var(--component-bg-color-inferior);
    padding: 4px 8px;
    border-radius: 4px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .chip-success {
    font-weight: 500;
    background-color: #9bf3b0;
    padding: 4px 8px;
    border-radius: 4px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .attachment-label {
    border: 1px solid;
    border-radius: 20px;
    padding: 5px 10px;
    color: $secondary;
    background: $secondary-1;
    font-size: 12px;
    &.ellipsis:hover {
      white-space: normal;
    }
  }

  .badge {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    font-size: 12px;
    background: $primary;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-top: -30px;
    margin-right: -20px;
    position: absolute;
    z-index: 1;
  }
}

#sheet .hint {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  cursor: pointer;
  border: 2px dotted;
  border-radius: 10px;
  background-color: #e0ebfa26;
  padding: 5px;

  .icon {
    color: var(--body-text-color-inverted);
  }

  .title {
    @extend .title-2;
    font-weight: bold;
    margin-bottom: 2px;
  }

  .description {
    color: var(--icon-color);
    text-align: center;
  }
}

#sheet .defaultBottom {
  position: absolute;
  bottom: 0;
}

#sheet .attachmentList,
#sheet .checkList {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);
  &:hover {
    background: #e0f7fa;
  }

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--divider-color);
  // }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }

  #avatar {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary;
    border-radius: 100%;
    border: 1px solid;
  }
}

#sheet .attachmentFileHeader {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#sheet .attachmentFileFrame {
  min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet {
  .chips {
    cursor: pointer;
    background-color: #fff !important;
    font-size: 12px !important;

    &.small {
      width: 75px;
    }

    &.medium {
      width: 95px;
    }
  }

  .primary-hover:hover {
    background: $primary-2 !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .green-hover:hover {
    background: $green-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .red-hover:hover {
    background: $red-2 !important;
  }

  .grey-hover:hover {
    background: $grey-2 !important;
  }
}

#modal #processHistory {
  table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }

    td.ellipsis:hover {
      overflow: visible;
      white-space: normal;
      word-break: break-all;
    }
  }
}
</style>
